import React from 'react'

import { useThemeOptions } from '#hooks'
import { Layout, LotBlock } from '#components'
import { PageIntro } from '#sections'
import { useFavoriteContext } from '../store/FavoriteContext'

export default function Favourites() {
  const { auctionsBackgroundImage } = useThemeOptions()
  const lots = useFavoriteContext()

  return (
    <Layout title="Your Favourites">
      <PageIntro
        title="Your Favourites"
        preview={false}
        backgroundImage={auctionsBackgroundImage}
      />
      <div className="bg-white relative pb-55 md:pb-65">
        <div className="container pt-45 md:pt-55">
          {lots && lots.length ? (
            <div className="flex flex-wrap -mx-8">
              {lots.map((lot) => (
                <div
                  className="w-full md:w-1/2 lg:w-1/3 px-8 mb-40"
                  key={lot.ID}
                >
                  <LotBlock lot={lot} />
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-65 text-27 font-semibold w-full">
              Sorry, you don't have any saved lots yet.
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}
